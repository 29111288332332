import React from 'react';
import PromotionsTemplate from '../../../templates/promotions';
import Leaderboard from '../../../components/Leaderboard';
import { ethGrinderLeaderboardPromotionData } from '../../../data/promotions';
import config from '../../../config';

const ethGrinderLeaderboardPage = ({ location, pageContext }) => (
    <PromotionsTemplate
        pageContext={{ ...pageContext, ...ethGrinderLeaderboardPromotionData }}
        location={location}
        component={<Leaderboard id={ethGrinderLeaderboardPromotionData.id} selectedTable="step3" leaderboardUrl={config.leaderboard.ethGrinderLeaderboardUrl} catalogUrl={config.leaderboard.ethGrinderLeaderboardCatalogueUrl} />}
    /> 
);

export default ethGrinderLeaderboardPage;
